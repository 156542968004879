import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { fetchOrdersByTableIdAction } from '../state/orders.reducer';
import { GlobalState } from '../state/@types/redux.interface';
import PanelInTable from '../components/table/panelInTable/panelInTable';
import TableWrapper from '../components/table/tableWrapper';
import { Loading } from '../components/common/loading';
import useGetIdsFromUrlAndFetch from '../utils/useGetIdsFromUrlAndFetch';

const TableNumberOfReceipts = styled(Link)`
    align-items: center;
    background-color: #6b257b;
    bottom: 0;
    color: #ffffff;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    height: 55px;
    justify-content: center;
    left: 0;
    position: absolute;
    text-align: center;
    text-decoration: none;
    width: 100%;
`;

const TablePage: FC = () => {
	const { t } = useTranslation();
	useGetIdsFromUrlAndFetch();
	const dispatch = useDispatch();
	const tableSelector = useSelector((state: GlobalState) => state.table);
	const ordersSelector = useSelector((state: GlobalState) => state.orders);
	const {
		table, loading, error, fetched,
	} = tableSelector;

	const { orders } = ordersSelector;

	useEffect(() => {
		if (table && fetched) {
			dispatch(fetchOrdersByTableIdAction(table.id));
		}
	}, [table]);

	return (
		<>
			{loading && !error && <Loading />}
			{!loading && !error && fetched && (
				<TableWrapper>
					<PanelInTable
						actionLowerDescription={t('table.dontWaitForWaiter')}
						actionName={t('common.pay')}
						actionUpperDescription={t('table.yourBill')}
						backgroundImagePath="/table/Zjadłeś.jpg"
						title={t('table.eaten')}
						to={`/table/orders?tableId=${table.id}`}
					/>
					<PanelInTable
						actionLowerDescription={t('table.enjoyMoment')}
						actionName={t('common.order')}
						actionUpperDescription={t('table.foodToTable')}
						backgroundImagePath="/table/Głodny.png"
						title={t('table.hungry')}
						to={`/table/menu?tableId=${table.id}`}
					/>
					<PanelInTable
						actionLowerDescription={t('table.dontWaitInLine')}
						actionName={t('common.order')}
						actionUpperDescription={t('table.foodPickup')}
						backgroundImagePath="/table/odbior_osobisty.png"
						title={t('table.enRoute')}
						to={`/table/menu?tableId=${table.id}&pickup=true`}
					/>
					<PanelInTable
						actionLowerDescription={null}
						actionName={t('common.pay')}
						actionUpperDescription={t('table.tip')}
						backgroundImagePath="/table/tip.png"
						title={t('table.noCash')}
						to={`/table/leave-tip?tableId=${table.id}`}
					/>
					<TableNumberOfReceipts to={`/table/orders?tableId=${table.id}`}>
						{`${t('table.numberOfBills')} ${orders?.length || 0}`}
					</TableNumberOfReceipts>
				</TableWrapper>
			)}
		</>
	);
};
export default TablePage;
